import React, { useEffect } from "react";
import './App.scss';
import { BrowserRouter } from "react-router-dom";
import RouterElement from "./routes";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store/store";
import { Provider, useDispatch, useSelector } from "react-redux";
import ToastComponent from "./components/Common/ToastComponent";
import { setOrgDetails, setToast, setUser } from "./redux/userSlice";
import { errorMsgWork, getCookie } from "./utils/utilFunction";
import { orgInfoApi, userInfo } from "./services/api.service";

const App = () => {
  return (
    <Provider store={store}>
      <div className="App">
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <AppLayout />
          </BrowserRouter>
          <ToastComponent />
        </PersistGate>
      </div>
    </Provider>
  );
}

const AppLayout = () => {
  const dispatch = useDispatch();
  const token = getCookie("persistent_auth");
  const { orgDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (orgDetails?.name) document.title = orgDetails?.name
  }, [orgDetails]);

  useEffect(() => {
    if (token) {
      getUserData();
    } else {
      getOrgInfo();
    }
  }, []);

  const getUserData = async () => {
    try {
      const userData = await userInfo()
      dispatch(setUser({ ...userData?.data }));
      dispatch(setOrgDetails(userData?.data?.organization));
    } catch ({ response }) {
      dispatch(setToast({ msg: errorMsgWork(response) }));
    }
  };

  const getOrgInfo = async () => {
    try {
      await orgInfoApi().then((res) => {
        dispatch(setOrgDetails(res?.data));
      });
    } catch (error) { }
  };

  return (
    <>
      <RouterElement />
    </>
  );
};


export default App;
