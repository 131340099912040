import { useContext } from "react";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { INTERNAL_SERVER_ERROR } from "./constants";

export const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey)
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <span className="accordion-header" onClick={decoratedOnClick}>
            {children}
            <i
                className={`click-arrow icon20 ${isCurrentEventKey && "click-arrow-down"
                    }`}
            />
        </span>
    );
}

export const convertDate = (inputDate) => {
    if (inputDate) {
        const [year, month, day] = inputDate?.split('-').map(Number); // Split the input and convert to numbers
        const dateObject = new Date(year, month - 1, day); // Create a Date object using local time

        const options = { year: "numeric", month: "long", day: "numeric" };
        return dateObject.toLocaleDateString("en-US", options);
    }
}

export const convertDateNew = (inputDate) => {
    const dateObject = new Date(inputDate);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return dateObject.toLocaleDateString("en-US", options);
}

export const convertDateNewShort = (inputDate) => {
    const dateObject = new Date(inputDate);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return dateObject.toLocaleDateString("en-US", options);
}

export const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const sendMsg = async (number, name, electionData, url) => {
    await window.open(
        `sms:${number}?body=${commonMsg(name, electionData)} Click here: ${url}.`,
        `_self`
    );
};

export const commonMsg = (name, electionData) => electionData?.election ? `${name}, I need your help! The ${electionData?.name
    } is coming up fast on ${convertDateNew(
        electionData?.election_date
    )} -- can you help three friends to make plans to vote?` : `Hi ${name}, I am sharing ${electionData?.name || electionData?.challenge?.name} with you, complete the challenge and spread awareness.`

export const convertPhoneNumber = (originalNumber) => originalNumber.toString().replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

export const errorMsgWork = (res) => {
    let obj = res?.data
    if (obj?.status === INTERNAL_SERVER_ERROR || obj === undefined || (
        obj?.constructor === String &&
        obj?.includes("<!DOCTYPE html>")
    )) {
        return "Something went wrong, please try again.";
    }
    if (obj?.error) {
        return obj.error
    }
    if (obj?.detail) {
        return obj.detail
    }
    if (obj?.constructor === String) {
        return obj
    }
    return Object.keys(obj).length > 1 ? Object.keys(obj).map(it => it.toUpperCase() + ": " + obj[it]).join(", ") : Object.keys(obj).map(it => obj[it][0].replace("This field", it.toUpperCase()))
}

export const returnCombinedProducts = (data) => {
    const combinedData = data.reduce((acc, item) => {
        let product = acc.find((p) => p.product_id === item.product_id);
        if (!product) {
            product = {
                product_id: item.product_id,
                product: item.product,
                colorOptions: [],
            };
            acc.push(product);
        }
        product.colorOptions.push({ ...item });
        return acc;
    }, []);
    return combinedData.map(it => ({ ...it, points: it.colorOptions.reduce((max, item) => (item.points > max ? item.points : max), 0) }))
}

export const returnCombinedColorData = (data) => {
    const combinedData = data.colorOptions.reduce((acc, item) => {
        let product = acc.find((p) => p.color_id === item.color_id);
        if (!product) {
            product = {
                color_id: item.color_id,
                color: item.color,
                code: item.code,
                image: item.image,
                genderOptions: [],
            };
            acc.push(product);
        }
        product.genderOptions.push({ ...item });
        return acc;
    }, []);
    const newData = combinedData.map(it => ({ ...it, genderOptions: returnCombinedGenderData(it.genderOptions) }))
    return { ...data, colorOptions: newData }
}

export const returnCombinedGenderData = (data) => {
    const combinedData = data.reduce((acc, item) => {
        let product = acc.find((p) => p.gender_id === item.gender_id);
        if (!product) {
            product = {
                gender_id: item.gender_id,
                gender: item.gender,
                sizeOptions: [],
            };
            acc.push(product);
        }
        product.sizeOptions.push({ ...item });
        return acc;
    }, []);
    const newData = combinedData.map(it => ({
        ...it,
        sizeOptions: it.sizeOptions.map(ele => ({
            ...ele,
            value: ele.size_id,
            label: ele.size,
        }))
    }))
    return newData
}