import { useRoutes } from "react-router-dom";
import { lazyLoadRoutes } from "./LazyLoadRoutes";
import { useSelector } from "react-redux";
import { USER_TYPE_CHOICES } from "../utils/constants";

const RouterElement = () => {
  const { user } = useSelector((state) => state.user);
  let newRoutes = [];
  if (user?.user_type === USER_TYPE_CHOICES.SUPER_ADMIN) {
    newRoutes = superAdminRoutes;
  }
  if (user?.user_type === USER_TYPE_CHOICES.ORG_ADMIN) {
    newRoutes = [...orgAdminRoutes, ...ambassadorRoutes];
  }
  if (user?.user_type === USER_TYPE_CHOICES.AMBASSADOR) {
    newRoutes = ambassadorRoutes;
  }
  if (user?.user_type === USER_TYPE_CHOICES.FRIEND) {
    newRoutes = friendRoutes;
  }
  if (user?.user_type === USER_TYPE_CHOICES.CONNECTION) {
    newRoutes = connectionRoutes;
  }
  return useRoutes(
    user?.user_type
      ? [...routes, ...newRoutes]
      : [
          {
            path: "/",
            name: "Sign-in",
            element: lazyLoadRoutes({ componentName: "SignIn" }),
          },
          {
            path: "*",
            name: "Sign-in",
            element: lazyLoadRoutes({ componentName: "SignIn" }),
          },
          ...routes,
        ]
  );
};

const routes = [
  {
    path: "/sign-up",
    name: "Sign-up",
    element: lazyLoadRoutes({ componentName: "SignUp" }),
  },
  {
    path: "/sign-in",
    name: "Sign-in",
    element: lazyLoadRoutes({ componentName: "SignIn" }),
  },
  {
    path: "/va-home",
    name: "Block Power",
    element: lazyLoadRoutes({ componentName: "Home", isProtected: true }),
  },
  {
    path: "/challenge-contacts/:id",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "ChallengeContactsList",
      isProtected: true,
    }),
  },
  {
    path: "/add-more-contacts",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "AddMoreContacts",
      isProtected: true,
    }),
  },
  {
    path: "/refresh-contacts",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "AddMoreContacts",
      isProtected: true,
    }),
  },
  {
    path: "/search-contacts",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "AddMoreContacts",
      isProtected: true,
      otherData: { search: true },
    }),
  },
  {
    path: "/contacts",
    name: "Block Power",
    element: lazyLoadRoutes({ componentName: "Contacts", isProtected: true }),
  },
  {
    path: "/contact-details/:id",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "ContactDetails",
      isProtected: true,
    }),
  },
  {
    path: "/voting-plan/:id",
    name: "Block Power",
    element: lazyLoadRoutes({ componentName: "VotingPlan", isProtected: true }),
  },
  {
    path: "/shared-challenge/:id/:user_referral/intro",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "AcceptedChallengeIntro",
    }),
  },
  {
    path: "/shared-challenge/:id/:user_referral/login",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "AcceptedInstantLogin",
    }),
  },
];

const superAdminRoutes = [];

const orgAdminRoutes = [
  {
    path: "/admin-dashboard",
    name: "Block Power",
    element: lazyLoadRoutes({ componentName: "OrgAdmin", isProtected: true }),
  },
];

const ambassadorRoutes = [
  {
    path: "/",
    name: "Block Power",
    element: lazyLoadRoutes({ componentName: "Profile", isProtected: true }),
  },
  {
    path: "/home",
    name: "Block Power",
    element: lazyLoadRoutes({ componentName: "Profile", isProtected: true }),
  },
  {
    path: "/settings",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "Setting",
      isProtected: true,
    }),
  },
  {
    path: "/challenge/:challenge_id/:org_challenge_id",
    name: "Block Power",
    element: lazyLoadRoutes({ componentName: "Challenge", isProtected: true }),
  },
  {
    path: "/friend-page/:frnd_id",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "FriendPageDetails",
      isProtected: true,
    }),
  },
  {
    path: "/card-active",
    name: "Block Power",
    element: lazyLoadRoutes({ componentName: "CardActive", isProtected: true }),
  },
  {
    path: "/card-active-form",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "CardActiveForm",
      isProtected: true,
    }),
  },
  {
    path: "/congratulations",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "CongratsCommonComponent",
      isProtected: true,
    }),
  },
  {
    path: "/verify-id",
    name: "Block Power",
    element: lazyLoadRoutes({ componentName: "VerifyId", isProtected: true }),
  },
  {
    path: "/redeem-tango",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "RedeemTango",
      isProtected: true,
    }),
  },
  {
    path: "/donate-org",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "RedeemDonateToOrg",
      isProtected: true,
    }),
  },
  {
    path: "/:id/merchandise",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "MerchandiseComponent",
      isProtected: true,
    }),
  },
  {
    path: "/shared-challenge/:id/:user_referral/process",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "AcceptedChallengeProcess",
      isProtected: true,
    }),
  },
  {
    path: "/shared-challenge/:id/:user_referral/final",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "AcceptedChallengeSubmit",
      isProtected: true,
    }),
  },
  {
    path: "*",
    name: "Block Power",
    element: lazyLoadRoutes({ componentName: "Profile", isProtected: true }),
  },
];

const friendRoutes = [
  {
    path: "/",
    name: "Block Power",
    element: lazyLoadRoutes({ componentName: "FriendHome", isProtected: true }),
  },
  {
    path: "/home",
    name: "Block Power",
    element: lazyLoadRoutes({ componentName: "FriendHome", isProtected: true }),
  },
  {
    path: "/settings",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "Setting",
      isProtected: true,
    }),
  },
  {
    path: "/shared-challenge/:id/:user_referral/process",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "AcceptedChallengeProcess",
      isProtected: true,
    }),
  },
  {
    path: "/shared-challenge/:id/:user_referral/final",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "AcceptedChallengeSubmit",
      isProtected: true,
    }),
  },
  {
    path: "/challenge/:challenge_id/:org_challenge_id",
    name: "Block Power",
    element: lazyLoadRoutes({
      componentName: "FriendChallenge",
      isProtected: true,
    }),
  },
  {
    path: "*",
    name: "Block Power",
    element: lazyLoadRoutes({ componentName: "FriendHome", isProtected: true }),
  },
];

const connectionRoutes = [];

export default RouterElement;
